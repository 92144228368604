import { graphql } from 'gatsby'

import Page from '../containers/Souvenir'

export default Page

export const query = graphql`
query ShopQuery {
  allWcProducts {
    nodes {
      wordpress_id
      slug
      name
      price
      categories {
        name
      }
    }
  }
}
`
