import React, { useMemo, useRef, useState } from 'react'
import { Center } from '@chakra-ui/layout'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../Layout'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Text from '../../components/Text'
import Carousel from '../../components/Carousel'
import Button from '../../components/Button'
// import PageModule from '../../components/PageModule'
import DonateInput from '../../components/DonateInput'
import { responsive } from '../../components/ThemeProvider/theme'

import Content from './Content'
import Banner from '../Banner'
import title from '../HomePage/images/sections/souvenir.svg'
import BackgroundImage from '../../components/BackgroundImage'


// name: '【觀茶・台灣禮盒內容物】',
// contents: [
//   {
//     title: '坪林蜜香紅茶｜15入',
//     desc: '品嚐進步的甜蜜，在回甘之間更認識台灣'
//   },
//   {
//     title: '坪林文山包種茶｜15入',
//     desc: '沈澱紛擾的雜緒，一起為臺灣找尋新的可能'
//   },
//   {
//     title: '議題折線掛耳小卡｜30入',
//     desc: '精細加工厚紙卡，泡茶聊天時的話題開端'
//   },
// ]

// support: '支持我們把掛心的事轉化成一件件衣服',
// desc: '透過數據所提供的線索，邀請你想像「時間」的不同可能性，重新思考自己在乎的是什麼。',

const productData = [
  {
    id: 10,
    donate: 600,
    support: '支持我們設計校園數據識讀教材',
    desc: '「數據與圖表判讀」是新課綱強調的重點素養，贊助此方案，即可獲得一份「數據識讀指南」，我們也可以幫忙寄送給你指定的學校！',
    img: [
      <StaticImage src="friend_1.png" layout="fullWidth" />,
      <StaticImage src="friend_2.png" layout="fullWidth" />,
      <StaticImage src="friend_3.png" layout="fullWidth" />,
    ],
    introductions: [
      {
        title: '讓數據成為你的好朋友\n從蒐集、清理、到判讀一次上手！',
        desc: '不論你是在看新聞、滑臉書看到數據出現，或是自己在做研究、蒐集資料需要用到數據。愛數的我們決定整理一份成為數據好朋友的指南，幫助數據在不同狀況下，能和更多人好好相處！',
      },
      {
        title: '3份指南\n組成台灣第一份數據識讀教材',
        desc: '和數據相處有幾個重要的眉角，我們將過往的經驗與技巧整理成3份指南，讓你能由淺入深完整了解如何使用與判讀數據！',
      },
    ],
    name: '數據識讀指南',
    contents: [
      {
        title: '數據問題探究流程｜1入',
        desc: '一張圖學會研究方法，一步一步帶你看懂數據'
      },
      {
        title: '數據實用技巧攻略｜1入',
        desc: '三步驟實戰技巧，學會跟數據相處的各種眉角'
      },
      {
        title: '數據使用Checklist｜1入',
        desc: '從判讀到研究，完整檢查數據中潛藏的陷阱'
      },
    ]
  },
  {
    id: 18,
    donate: 480,
    support: '支持我們呈現更多台灣真實樣貌',
    desc: '在黑與白之間，探尋每一條隨著時間軌跡改變的折線，不論好壞；\n在黑與白之間，用你的觸覺選擇一條線漫步，展開⼀場知性的旅程。',
    img: [
      <StaticImage src="linechart_1.png" layout="fullWidth" />,
      <StaticImage src="linechart_2.png" layout="fullWidth" />,
      <StaticImage src="linechart_3.png" layout="fullWidth" />,
    ],
    introductions: [
      {
        title: '在100種起伏之間\n重新認識島嶼的過去',
        desc: '這20年，你過得好嗎？臺灣，好嗎？」\n我們100個台灣重要議題的變化都放在海報上了！\n\n海報分為黑白雙色，分別梳理100個二十年來台灣「正在進步」及「令人擔憂」的數據折線。你可以在最常經過的空間掛上海報，將這些重要的事持續放在⼼上。也可以送給關心社會議題的朋友，找到100種適合開啟對話的方式！',
      },
      {
        title: '摸得到的折線圖\n有些改變不用說出口也感受得到',
        desc: '海報利用特殊的打凸加工取代印刷，讓這些變化不僅能「讀」，也能透過「摸」的方式來感受。\n\n特別選用加厚卡紙，即使長時間張貼也能保持細緻質感，忠實呈現台灣20年來各領域的數據。',
      },
    ],
    name: '線實・台灣海報組',
    contents: [
      {
        title: '數據折線黑海報｜1入',
        desc: '50件令人擔憂的事，需要你一起關注改變'
      },
      {
        title: '數據折線白海報｜1入',
        desc: '50件正在進步的事，需要你共同持續努力'
      },
    ]
  },
  // {
  //   id: 14,
  //   donate: 780,
  //   support: '支持我們推廣圖表判讀',
  //   desc: '從小六到上班族都能讀懂的圖表書\n推廣圖表判讀，我們需要借助你的力量！',
  //   img: [
  //     <StaticImage src="chart_1.png" layout="fullWidth" />,
  //     <StaticImage src="chart_2.png" layout="fullWidth" />,
  //     <StaticImage src="chart_3.png" layout="fullWidth" />,
  //   ],
  //   introductions: [
  //     {
  //       title: '用一杯茶的時間\n認識生活裡的30個重要議題',
  //       desc: '透過30杯茶，我們邀請你一起和身邊的人展開對話，透過茶讓心情平靜，讓掛卡上的資訊促成話題，讓每一個關心土地的你用一杯茶的時間，重新認識台灣這二十年來的變化。',
  //     },
  //     {
  //       title: '除了對數據講究\n我們對茶葉也同樣要求',
  //       desc: '堅持直接與坪林老茶廠欣泰茶行合作，特選兩種台灣茶葉，並使用環保濾材—日本出品的玉米澱粉材質茶袋，所有茶葉都經過『負壓去雜質』、『色選分級』、『磁性去屑』等工序處理，如同處理資料的過程，只把最好的留下',
  //     },
  //   ],
  //   name: '圖表家族',
  //   contents: [
  //     {
  //       title: '坪林蜜香紅茶｜15入',
  //       desc: '品嚐進步的甜蜜，在回甘之間更認識台灣'
  //     },
  //     {
  //       title: '坪林文山包種茶｜15入',
  //       desc: '沈澱紛擾的雜緒，一起為臺灣找尋新的可能'
  //     },
  //     {
  //       title: '議題折線掛耳小卡｜30入',
  //       desc: '精細加工厚紙卡，泡茶聊天時的話題開端'
  //     },
  //   ]
  // },
  {
    id: 16,
    donate: 820,
    support: '支持我們改造更多重要報告',
    desc: '如果從數據開始一場對話，會有什麼不同？贊助此方案，即可獲得一份【觀茶・臺灣組合】',
    img: [
      <StaticImage src="tea_1.png" layout="fullWidth" />,
      <StaticImage src="tea_2.png" layout="fullWidth" />,
      <StaticImage src="tea_3.png" layout="fullWidth" />,
    ],
    introductions: [
      {
        title: '用一杯茶的時間\n認識生活裡的30個重要議題',
        desc: '透過30杯茶，我們邀請你一起和身邊的人展開對話，透過茶讓心情平靜，讓掛卡上的資訊促成話題，讓每一個關心土地的你用一杯茶的時間，重新認識台灣這二十年來的變化。',
      },
      {
        title: '除了對數據講究\n我們對茶葉也同樣要求',
        desc: '堅持直接與坪林老茶廠欣泰茶行合作，特選兩種台灣茶葉，並使用環保濾材—日本出品的玉米澱粉材質茶袋，所有茶葉都經過『負壓去雜質』、『色選分級』、『磁性去屑』等工序處理，如同處理資料的過程，只把最好的留下',
      },
    ],
    name: '觀茶・台灣禮盒',
    contents: [
      {
        title: '坪林蜜香紅茶｜15入',
        desc: '品嚐進步的甜蜜，在回甘之間更認識台灣'
      },
      {
        title: '坪林文山包種茶｜15入',
        desc: '沈澱紛擾的雜緒，一起為臺灣找尋新的可能'
      },
      {
        title: '議題折線掛耳小卡｜30入',
        desc: '精細加工厚紙卡，泡茶聊天時的話題開端'
      },
    ]
  },
]

const Souvenir = ({ data: { allWcProducts: { nodes: products } } }) => {
  const [donate, setMoney] = useState()
  const donteRef = useRef()
  const productSlugs = useMemo(() => products.reduce((obj, p) => {
    obj[p.wordpress_id] = p.slug
    return obj
  }, {}), [products])
  const freeDonateSlug = useMemo(() => products.find((p) => p.categories.some(cat => cat.name === '自由'))?.slug, [products])
  return (
    <Layout overflow="hidden" title="有數紀念品店" bg="custom.yellowBg">
      <Banner
        photo={<StaticImage src="banner-mobile.png" layout="fullWidth" />}
        photoWeb={<StaticImage src="banner-web.png" layout="fullWidth" />}
        title={<BackgroundImage src={title} ratio={487 / 91} />}
        left={responsive('-3em', 0)}
        text={"紀念品店很多\n但你一定沒看過這麼有數的\n這裡目前是島的唯一收入來源"}
        textWidth="60%"
        textLeft={responsive('55%', 'unset')}
        textBottom={responsive('2em', 'unset')}
        overflow="visible"
      />
      <Box bg="custom.yellowBg" pt={responsive('0.25em', '5.875em')}>
        <Box maxWidth="380px" mx={responsive('1.5em', 'auto')}>
          <Text
            fontWeight="900"
            textAlign="center"
            fontSize={responsive('1.375em', '1.5em')}
          >100%使用於計畫營運</Text>
          <Text.S mt={responsive('0.75rem', '1em')}>
            每份紀念品的收入都會100%都入到計畫營運，推動重要數據報告改造、數據識讀教育，並且每年定期公開計畫成果報告！
          </Text.S>
          <Box>
            <Button.Yellow
              mt="1rem"
              border="3px solid"
              py={responsive('0.875rem', '1rem')}
              fontSize={responsive('1.25em', '1.375em')}
              width={responsive('320px', '416px')}
              onClick={() => donteRef.current.scrollIntoView({ behavior: 'smooth' })}
            >逛逛有數紀念品</Button.Yellow>
          </Box>
          <Box>
            <Button.Yellow
              mt="1rem"
              border="3px solid"
              py={responsive('0.875rem', '1rem')}
              fontSize={responsive('1.25em', '1.375em')}
              width={responsive('320px', '416px')}
              to={`/2021%E8%A8%88%E7%95%AB%E4%BB%8B%E7%B4%B9/`}
            >看年度計畫報告
            </Button.Yellow>
          </Box>
        </Box>
        <Text
          mt={responsive('3.375rem', '5.25em')}
          textAlign="center"
          fontWeight="900"
          fontSize={responsive('1.375em', '1.5em')}
        >自由贊助</Text>
        <Box maxWidth="1116px" mx="auto">
          <Center
            bg="white"
            mx="0.5em"
            mt={responsive('0.625em', '1.75em')}
            py={responsive('1.25em', '2.25em')}
            px={responsive('0.75em', 0)}
            borderRadius="1.25em"
          >
            <Box textAlign="center">
              <Text.Bold fontSize={responsive('1em', '1.25em')}>贊助我們一杯咖啡，讓我們能做更多</Text.Bold>
              <Box>
                <DonateInput onChange={(e) => setMoney(e.target.value)} />
                {typeof donate !== 'undefined' && !Number.isInteger(+donate) && (
                  <Text textAlign="center" mt="0.25rem" fontSize="0.75em" color="red">請填入整數數字</Text>
                )}
              </Box>
              <Box>
                <Button.Yellow
                  disabled={!Number.isInteger(+donate) || !+donate}
                  mt="1rem"
                  border="3px solid"
                  py={responsive('0.875rem', '1rem')}
                  fontSize={responsive('1.25em', '1.375em')}
                  width={responsive('320px', '416px')}
                  to={`/checkout/${freeDonateSlug}?donate=${donate}`}
                >確認贊助</Button.Yellow>
              </Box>
            </Box>
          </Center>
        </Box>
        <Text.Bold
          ref={donteRef}
          textAlign="center"
          fontSize={responsive('1.375em', '1.5em')}
          pt={responsive('3em', '5.375em')}
        >贊助方案與特選回饋品</Text.Bold>
        <Flex
          flexWrap="wrap"
          mx={responsive('1em', 'auto')}
          mt={responsive('1em', 0)}
          mb={responsive('1.25em', '4em')}
          maxWidth="1116px"
          borderRadius={responsive('1.25rem', 0)}
          overflow="hidden"
        >
          {productData.map((d, i) => (
            <Box id={d.name} mt={responsive(0, '2em')} width={responsive('100%', '50%')} key={i}>
              <Box
                bg="white"
                mx={responsive(0, '0.5em')}
                height="100%"
                borderRadius={responsive(0, '2.5rem')}
                p={responsive('1.625em', '3.75em')}
              >
                <Text>{d.title}</Text>
                <Text>{d.support}</Text>
                <Box height={responsive('auto', '7em')}>
                  <Text mt={responsive('0.25em', '1.625em')} whiteSpace="pre-wrap">{d.desc}</Text>
                </Box>
                <Carousel mt={responsive(responsive('0.625em', '0'))} nodot arrows={false} autoplay>
                  {d.img.map((picture, i) => (
                    <Box height="100%" key={i}>
                      {picture}
                    </Box>
                  ))}
                </Carousel>
                <Content introductions={d.introductions} contents={d.contents} name={d.name} />
                <Box textAlign="center" maxWidth={responsive('292px', '416px')} mt={responsive('1.25em', '2.25em')}>
                  <Button.Yellow
                    py={responsive('0.875rem', '1rem')}
                    fontSize={responsive('1.25em', '1.375em')}
                    width="100%"
                    to={`/checkout/${productSlugs[d.id]}/`}
                  >贊助獲得紀念品</Button.Yellow>
                </Box>
              </Box>
            </Box>
          ))}
        </Flex>
      </Box>
    </Layout>
  )
}

export default Souvenir
