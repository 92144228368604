import React, { useState } from 'react'
import { Icon, Collapse } from '@chakra-ui/react';
import { RiArrowDownSLine } from "react-icons/ri";

import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Text from '../../components/Text'
import { responsive } from '../../components/ThemeProvider/theme';

const Content = ({ name, introductions, contents, ...props }) => {
    const [whole, setOpen] = useState()
    return (
        <Box
          mt={responsive('1.25em', '2.5em')}
          py={responsive('1em', '1em')}
          px={responsive('1.5em', '2.625em')}
          borderRadius={whole ? '2.125em' : '2.5em'}
          bg="custom.bgGray"
          {...props}
        >
          <Flex justifyContent="space-between" onClick={() => setOpen(!whole)} cursor="pointer">
            <Text fontSize={responsive('0.875em', '1.25em')} fontWeight="500">完整組合內容</Text>
            <Box width="1em"><Icon transition="transform 300ms" transform={`rotate(${whole ? 180 : 0}deg)`} as={RiArrowDownSLine} w={8} h={8} /></Box>
          </Flex>
          <Collapse in={whole} animateOpacity>
            <Box mt="1rem" fontSize={responsive('0.75em', '0.875em')}>
              {introductions.map(({ title, desc }, k) => (
                <Box mt={k && '2rem'} key={k}>
                  <Text.Bold whiteSpace="pre-wrap">
                  {title}
                  </Text.Bold>
                  <Text whiteSpace="pre-wrap">{desc}</Text>
                </Box>
                ))}
                <Text.Bold>【{name}內容物】</Text.Bold>
                <Box mt="2rem">
                {contents.map(({ title, desc }, k) => (
                  <Box key={k}>
                  <Text.Bold>{title}</Text.Bold>
                  <Text>{desc}</Text>
                  </Box>
                ))}
              </Box>
          </Box>
          </Collapse>
        </Box>
    )
}

export default Content
