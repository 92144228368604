import React from 'react'
import Input  from './Input'
import { responsive } from './ThemeProvider/theme'

const DonateInput = (props) => (
  <Input
    mt="1.25em"
    py={responsive('1.625rem', '1.8rem')}
    bg="white"
    width={responsive('320px', '416px')}
    border="3px solid"
    borderColor="black"
    borderRadius="2.5em"
    lineHeight="1"
    placeholder="可自行輸入金額(NTD)"
    fontSize={responsive('0.875em', '1.375em')}
    _placeholder={{
      textAlign: 'center',
    }}
    _focus={{
      borderColor: 'black'
    }}
    _hover={{
      borderColor: 'black'
    }}
    {...props}
  />
)

export default DonateInput

